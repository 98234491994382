import { useRef, useEffect, useState, memo } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import logo from "../assets/images/site/logo.png";
import novruz from "../assets/images/site/novruz.png";
import down from "../assets/icons/down.svg";
import settings from "../assets/icons/settings.svg";
import support from "../assets/icons/support.svg";
import logout_icon from "../assets/icons/logout.svg";
import bars from "../assets/icons/bars.svg";
import HeaderNavigation from "./HeaderNavigation";
import aze from "../assets/icons/aze.svg";
import eng from "../assets/icons/eng.svg";
import rus from "../assets/icons/rus.svg";
import MobileSidebar from "./MobileSidebar";
import SupportModal from "./SupportModal";
import SantaHat from "../assets/images/site/santa-hat.png";
import March8 from "./March8";
import closeMenu from "../assets/icons/close-menu.svg";
import { MIX_API_URL, MIX_APP_URL } from "../env";

function Header({
    headerMenuShown,
    dispatch,
    user,
    translations,
    supportModal,
}) {
    const nav = useNavigate();
    const loc = useLocation();
    let navigateTo = "user";
    if (loc.pathname.includes("admin")) {
        navigateTo = "admin";
    } else if (loc.pathname.includes("instructor")) {
        navigateTo = "instructor";
    } else if (loc.pathname.includes("barmen")) {
        navigateTo = "barmen";
    }
    const [mobileMenuShown, setMobileMenuShown] = useState(
        window.innerWidth <= 672
    );
    useEffect(() => {
        window.addEventListener("resize", () => {
            setMobileMenuShown(window.innerWidth <= 672);
        });
    }, []);
    // const darkModeSwitcher = useRef();
    const check8March = new Date();
    const node = useRef();
    const node2 = useRef();
    const toggleHeaderMenu = (e) => {
        //For Future Dark Mode
        // if (e.composedPath().includes(darkModeSwitcher.current)) {
        //     return;
        // }
        let payload = false;
        if (node?.current?.contains(e.target)) {
            payload = !headerMenuShown;
        }
        dispatch({
            type: "HEADER_MENU",
            payload: payload,
        });
        if (!node2?.current?.contains(e.target)) {
            setLangMenu(false);
        }
    };
    useEffect(() => {
        document.addEventListener("click", toggleHeaderMenu);
        return () => {
            document.removeEventListener("click", toggleHeaderMenu);
        };
    }, [headerMenuShown]);
    useEffect(() => {
        setMobileSideBar(false);
    }, [loc.pathname]);
    const logout = (e) => {
        e.preventDefault();
        fetch(`${MIX_API_URL}${navigateTo}/logout`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
        })
            .then((a) => a.json())
            .then(() => {
                nav(`/${navigateTo}/login`);
                window.localStorage.removeItem("token");
                dispatch({
                    type: "SET_USER",
                    payload: null,
                });
                dispatch({
                    type: "SET_BIRTHDAYSHOWN",
                    payload: false,
                });
                dispatch({
                    type: "SET_BIRTHDAYSSHOWN",
                    payload: false,
                });
            });
    };
    const changeLanguage = (lang) => {
        if (translations.activeLanguage === lang) {
            return;
        }
        fetch(`${MIX_API_URL}changelanguage`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + window.localStorage.getItem("token"),
            },
            body: JSON.stringify({
                lang: lang,
            }),
        });
        window.localStorage.setItem("lang", lang);
        dispatch({
            type: "SET_LANGUAGE",
            payload: lang,
        });
        setLangMenu(false);
    };
    const langImages = {
        aze: aze,
        eng: eng,
        rus: rus,
    };
    const [langMenu, setLangMenu] = useState(false);
    const [mobileSideBar, setMobileSideBar] = useState(false);
    //For future Dark Mode
    // const [darkMode, setDarkMode] = useState(false);
    // const modeSwitcher = (e) => {
    //     e.preventDefault();
    //     setDarkMode(!darkMode);
    // };
    // useEffect(() => {
    //     if (!darkMode) {
    //         document.body.classList.remove("dark__mode");
    //     } else {
    //         document.body.classList.add("dark__mode");
    //     }
    // }, [darkMode]);
    const [isNewYear, setIsNewYear] = useState(false);
    useEffect(() => {
        let newYear_date = new Date();
        let newYear_month = newYear_date.getMonth();
        let newYear_day = newYear_date.getDate();
        setIsNewYear(
            (newYear_month === 0 && newYear_day <= 7) ||
            (newYear_month === 11 && newYear_day >= 24)
        );
    }, []);
    const date = new Date();
    const [isNovruz, setIsNovruz] = useState(
        date.getMonth() === 2 && date.getDate() >= 18 && date.getDate() <= 24
    );
    return (
        user && (
            <>
                {/* {isNovruz && (
                    <div className="novruz">
                        <img onClick={()=>setIsNovruz(false)} className="close" src={closeMenu} alt="" />
                        <img src={logo} alt="" />
                        <img className="novruz__flower" src={novruz} alt="" />
                        <h3>
                            Coders Azerbaijan adından Sizi Novruz bayramı
                            münasibətilə ürəkdən təbrik edir, hər birinizə can
                            sağlığı, səadət və gələcək işlərinizdə uğurlar
                            arzulayırıq.
                        </h3>
                    </div>
                )} */}
                {supportModal && <SupportModal />}
                {mobileMenuShown && (
                    <MobileSidebar
                        setMobileSideBar={setMobileSideBar}
                        navigateTo={navigateTo}
                        logout={logout}
                        mobileSideBar={mobileSideBar}
                        changeLanguage={changeLanguage}
                    />
                )}
                <header>
                    <div className="logo">
                        {isNewYear && <div className="snowing__effect"></div>}
                        {isNewYear && (
                            <img
                                id="santa"
                                src="https://www.animatedimages.org/data/media/359/animated-santa-claus-image-0420.gif"
                                alt=""
                            />
                        )}
                        <NavLink to={`${navigateTo}/`}>
                            {isNewYear && (
                                <img id="santa__hat" src={SantaHat} alt="" />
                            )}
                            {/* LOGO */}
                            <img src='/images/logo2.svg' alt="" />
                        </NavLink>
                        <HeaderNavigation where={navigateTo} />
                    </div>
                    {user?.date_of_birth && (
                        <>
                            <div className="menu-container">
                                <div className="change-language" ref={node2}>
                                    <div
                                        className="active-language"
                                        onClick={() => setLangMenu((a) => !a)}
                                    >
                                        <img
                                            src={
                                                langImages[
                                                translations.activeLanguage
                                                ]
                                            }
                                            alt=""
                                        />
                                        <p>
                                            {translations.activeLanguage.toUpperCase()}
                                        </p>
                                        <img
                                            className="icon"
                                            style={{
                                                transform: `rotate(${langMenu ? 180 : 0
                                                    }deg)`,
                                            }}
                                            src={down}
                                            alt=""
                                        />
                                    </div>
                                    <ul
                                        className={`language-dropdown ${langMenu ? "shown" : ""
                                            }`}
                                    >
                                        <li
                                            onClick={() =>
                                                changeLanguage("aze")
                                            }
                                        >
                                            <img src={aze} alt="" />
                                            <p>AZE</p>
                                        </li>
                                        <li
                                            onClick={() =>
                                                changeLanguage("eng")
                                            }
                                        >
                                            <img src={eng} alt="" />
                                            <p>ENG</p>
                                        </li>
                                        <li
                                            onClick={() =>
                                                changeLanguage("rus")
                                            }
                                        >
                                            <img src={rus} alt="" />
                                            <p>RUS</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="menu" ref={node}>
                                    {user && (
                                        <img
                                            className="user-image"
                                            src={
                                                user?.photo.startsWith("blob")
                                                    ? user?.photo
                                                    : user?.photo !== 'student.png' ? `${MIX_APP_URL}storage/avatars/${user?.photo}` : `/images/student.png`

                                            }
                                            alt=""
                                        />
                                    )}
                                    <h3 className="user-name" id="open-menu">
                                        {user?.name}
                                        <img
                                            style={{
                                                transform: `rotate(${headerMenuShown ? 180 : 0
                                                    }deg)`,
                                            }}
                                            src={down}
                                            className="icon"
                                            alt=""
                                        />
                                    </h3>
                                </div>
                                <ul
                                    className={`menu-dropdown  ${headerMenuShown
                                        ? "menu-dropdown-active"
                                        : ""
                                        }`}
                                >
                                    <li>
                                        <NavLink
                                            to={`${navigateTo}/editprofile`}
                                        >
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].settings
                                            }
                                            <img src={settings} alt="" />
                                        </NavLink>
                                    </li>
                                    <li
                                        style={{
                                            display:
                                                navigateTo !== "admin"
                                                    ? ""
                                                    : "none",
                                        }}
                                    >
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                dispatch({
                                                    type: "SUPPORT_MODAL",
                                                    payload: true,
                                                });
                                            }}
                                        >
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].support
                                            }
                                            <img src={support} alt="" />
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            onClick={logout}
                                            id="logout"
                                            href="#"
                                        >
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].logout
                                            }
                                            <img src={logout_icon} alt="" />
                                        </a>
                                    </li>
                                    {/* <li
                                        className="dark__mode__switcher__list"
                                        ref={darkModeSwitcher}
                                    >
                                        <a onClick={modeSwitcher} href="#">
                                            <p>Qaranlıq Rejim</p>
                                            <div className="dark__mode__switcher"></div>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                            <div
                                onClick={() => setMobileSideBar(true)}
                                className="responsive-menu-toggler"
                            >
                                <img src={bars} alt="" />
                            </div>
                        </>
                    )}
                    {check8March.getDate() === 8 &&
                        check8March.getMonth() === 2 ? (
                        <March8 />
                    ) : null}
                </header>
            </>
        )
    );
}
const t = (a) => {
    return {
        headerMenuShown: a.headerMenuShown,
        user: a.user,
        translations: a.translations,
        supportModal: a.supportModal,
    };
};
export default memo(connect(t)(Header));
