import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { MIX_API_URL } from "../env";
function ProtectedRoute({ children, where, dispatch }) {
    const nav = useNavigate();
    const loc = useLocation();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getUserInfo = async () => {
            await fetch(`${MIX_API_URL}${where}`, {
                method: "GET",
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${window.localStorage.getItem(
                        "token"
                    )}`,
                },
            })
                .then((res) => res.json())
                .then((json) => {
                    if (json.name) {
                        dispatch({
                            type: "SET_USER",
                            payload: json,
                        });
                    } else {
                        window.localStorage.removeItem("token");
                        dispatch({
                            type: "SET_USER",
                            payload: null,
                        });
                        nav(`/${where}/login`);
                    }
                });
        };
        getUserInfo().then(() => setLoading(false));
    }, [loc.pathname]);
    const token = window.localStorage.getItem("token");
    if (!token) {
        dispatch({
            type: "SET_USER",
            payload: null,
        });
        return <Navigate to={`/${where}/login`} />;
    }

    return (
        <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="inner-section"
            >
                {!loading && children}
            </motion.div>
        </>
    );
}

export default connect()(ProtectedRoute);
