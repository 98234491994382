import { useState } from "react";
import a1 from "../assets/images/site/8mart1.png";
import a2 from "../assets/images/site/8mart2.png";
import a3 from "../assets/images/site/8mart3.png";
import a4 from "../assets/images/site/8mart4.png";
import a5 from "../assets/images/site/8mart5.png";
import lastMarch from "../assets/images/site/lastmarch.png";
import lastMarch_mobile from "../assets/images/site/mobile_march.png";
function March8() {
    const data = [
        {
            id: 1,
            name: "Ada Lovelace",
            text: "Ada Lovelace bir çoxları tərəfindən ilk kompüter proqramçısı kimi tanınnır. O, 1840 Charles Babbage Analitik mühəriklər üzərində işləyib, hansı ki bunlar bir maşın tərəfindən işlənən ilk algoritmlər hesab olunurlar.",
            image: a1,
        },
        {
            id: 2,
            name: "Sister Mary Kenneth Keller",
            text: "Mary Kenneth Keller Bacı katolik rahibəsi idi və ABŞ-da  kompüter elmləri üzrə Ph.D. dərəcəsi alan ilk qadındır. O, BASİC proqramlaşdırma dilinin inkişaf etdirilməsində iştirak edib və qadınlar üçün kompüter elmləri təhsilini təşviq edirdi.",
            image: a2,
        },
        {
            id: 3,
            name: "Margaret Hamilton",
            text: "Margaret Hamilton 1960-70-ci Apollo kosmik proqramı üzərində işləyən kompüter elmləri üzrə mütəxəssis idi. O, bu layihənin aparıcı proqram mühəndisi idi və onun işi sayəsində layihə uğurlu oldu. O, həmçinin kompüter elmləri sahəsində “Proqram Mühəndisliyi” konseptini yaradıb.",
            image: a3,
        },
        {
            id: 4,
            name: "Grace Hopper",
            text: "Grace Hopper kompüter elmləri üzrə mütəxəssis, riyaziyyatçı və ABŞ Hərbi Dəniz Qüvvələri Admiralı olub. O, insan tərəfindən oxunan kodları maşınlar tərəfindən oxunan kodlara çevirən ilk tərtibatçı kimi tanınır. O, COBOL proqramlaşdırma dilinin yaradıcısıdır və kompüter elmləri üzrə təhsilin öncüllərindən biridir.",
            image: a4,
        },
        {
            id: 5,
            name: "Jean Jennings Bartik",
            text: "Jean Jennings Bartik Jean Bartik ENİAC kompüterlərini ilk mərhılədə proqramlaşdıran şəxslərdən biridir, hansı ki onlar ilk elektron kompüterlərdə hesab olunurlar. O və onun komandası ikinci Dünya Müharibəsi dövründə ABŞ ordusu üçün ENİAC üçün hesablamaları proqramlaşdırmaqdan məsul idilər.",
            image: a5,
        },
    ];
    const [show, setShow] = useState(true);
    const [index, setIndex] = useState(0);
    document.body.overflow = show ? "hidden" : "unset";
    return show ? (
        <div className="march8">
            {data[index] && (
                <>
                    <div className="row8">
                        <img src={data[index].image} alt="" />
                        <svg
                            viewBox="0 0 183 58"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.443359 0.149963H11.881L29.2338 24.1508V24.0984L46.8222 0.149963H58.1552V55.925H45.1995V20.8529L30.8828 40.9016H27.6111L13.4514 20.7221V55.925H0.443359V0.149963Z"
                                fill="rgba(255,255,255,0.6)"
                            />
                            <path
                                d="M65.6934 34.6996C65.6934 22.3196 73.9902 12.0859 85.1662 12.0859C91.4478 12.0859 96.4992 14.5462 99.0642 18.891V13.4469H111.051V55.9522H99.038V50.5867C96.4468 54.9052 91.4477 57.3655 85.2447 57.3655C73.8594 57.3394 65.6934 47.158 65.6934 34.6996ZM98.5669 34.6996C98.5669 28.575 94.0651 23.7068 88.3855 23.7068C82.8106 23.7068 78.2041 27.8422 78.2041 34.6996C78.2041 41.9234 82.9677 45.6923 88.3332 45.6923C94.1174 45.6923 98.5669 40.9288 98.5669 34.6996Z"
                                fill="rgba(255,255,255,0.6)"
                            />
                            <path
                                d="M120.237 13.4466H132.329V19.4402C134.266 15.9069 137.407 13.4466 142.38 13.4466H147.3V24.2038H143.086C136.726 24.2038 132.905 28.5223 132.905 36.3743V55.9518H120.237V13.4466Z"
                                fill="rgba(255,255,255,0.6)"
                            />
                            <path
                                d="M158.635 39.3828V24.2024H150.469V13.4452H158.635V1.17001L171.277 1.09149V13.4452H182.767V24.2024H171.277V39.9587C171.277 44.094 173.423 45.5335 176.067 45.5335H182.741V55.9505H173.345C167.063 55.9505 158.635 54.0922 158.635 39.3828Z"
                                fill="rgba(255,255,255,0.6)"
                            />
                        </svg>
                    </div>
                    <div className="row8__data">
                        <h3>{data[index].name}</h3>
                        <p>{data[index].text}</p>
                    </div>
                    <div className="row8__logo">
                        {index > 0 && (
                            <div
                                id="demo333"
                                onClick={() => setIndex(index - 1)}
                            >
                                <svg
                                    width="77"
                                    height="77"
                                    viewBox="0 0 77 77"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M49.3912 59.0162C48.6919 59.7155 47.558 59.7155 46.8587 59.0162L27.6087 39.7662C26.9094 39.0669 26.9094 37.9331 27.6087 37.2338L46.8587 17.9838C47.5581 17.2845 48.6919 17.2845 49.3912 17.9838C50.0905 18.6831 50.0905 19.8169 49.3912 20.5162L31.4074 38.5L49.3912 56.4838C50.0905 57.1831 50.0905 58.3169 49.3912 59.0162Z"
                                        fill="white"
                                        fillOpacity="0.8"
                                    />
                                </svg>
                            </div>
                        )}
                        <svg
                            width="150"
                            height="24"
                            viewBox="0 0 150 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M22.4725 5.869V0H6.49925C5.82022 0 5.17353 0.314411 4.68851 0.838428L1.03471 4.75109C0.388015 5.44978 0 6.39301 0 7.40611V16.559C0 17.5721 0.35568 18.5153 1.03471 19.214L4.68851 23.1616C5.17353 23.6856 5.85256 23.9651 6.53158 23.9651H22.5049V18.131H5.43221V5.90393L22.4725 5.869Z"
                                fill="white"
                            />
                            <path
                                d="M59.9807 5.869V0H66.674C67.353 0 68.032 0.279476 68.517 0.838428L72.1708 4.78603C72.8175 5.48472 73.2055 6.42795 73.2055 7.44105V16.5939C73.2055 17.607 72.8499 18.5502 72.1708 19.2489L68.517 23.1965C68.032 23.7205 67.353 24 66.674 24H59.9807V18.131H67.741V5.90393L59.9807 5.869Z"
                                fill="white"
                            />
                            <path
                                d="M46.7882 4.75109L43.1343 0.838428C42.6493 0.314411 41.9703 0 41.2913 0H32.1729C31.4939 0 30.8149 0.279476 30.3299 0.838428L26.6761 4.75109C26.0294 5.44978 25.6414 6.39301 25.6414 7.40611V16.559C25.6414 17.5371 25.997 18.5153 26.6761 19.214L30.3299 23.1616C30.8149 23.6856 31.4939 24 32.1729 24H41.2913C41.9703 24 42.6493 23.6856 43.1343 23.1616L46.7882 19.214C47.4348 18.5153 47.8229 17.5721 47.8229 16.559V7.40611C47.8229 6.42795 47.4348 5.48472 46.7882 4.75109ZM42.3907 18.131H31.1059V5.90393H42.3907V18.131Z"
                                fill="white"
                            />
                            <path
                                d="M56.3592 0H51.1533V24H56.3592V0Z"
                                fill="white"
                            />
                            <path
                                d="M87.6913 14.917L93.3175 5.86899H82.0004V18.0961H98.8144V24H83.0675C82.3884 24 81.7094 23.6856 81.2244 23.1616L77.5706 19.214C76.9239 18.5153 76.5359 17.572 76.5359 16.5589V7.40611C76.5359 6.39301 76.8916 5.44977 77.5706 4.75108L81.2244 0.803485C81.7094 0.279468 82.3884 -0.0349426 83.0675 -0.0349426H98.8144V5.83405L93.2205 14.8821H87.6913V14.917Z"
                                fill="white"
                            />
                            <path
                                d="M119.961 1.08297L123.744 5.13537C124.165 5.58952 124.391 6.18341 124.391 6.81223V10.7598C124.391 11.3886 124.165 11.9825 123.744 12.4017L118.797 17.8166L124.359 24H118.797L113.236 17.8166V12.2271H118.959V5.58952H107.383V24H101.983V0H117.471C118.409 0 119.314 0.384279 119.961 1.08297Z"
                                fill="white"
                            />
                            <path
                                d="M150 11.9825L133.089 7.61572V5.869H150V0H134.512C133.574 0 132.701 0.38428 132.054 1.1179L127.528 6.00873V12.0175L144.406 16.4192V18.1659H127.528V24H142.919C143.824 24 144.697 23.6157 145.344 22.952L150 18.0961V11.9825Z"
                                fill="white"
                            />
                        </svg>
                        {index < data.length && (
                            <div
                                id="demo444"
                                onClick={() => setIndex(index + 1)}
                            >
                                <svg
                                    width="77"
                                    height="77"
                                    viewBox="0 0 77 77"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M27.6087 17.9838C28.308 17.2845 29.4418 17.2845 30.1411 17.9838L49.3911 37.2338C50.0905 37.9331 50.0905 39.0669 49.3911 39.7662L30.1411 59.0162C29.4418 59.7155 28.308 59.7155 27.6087 59.0162C26.9094 58.3169 26.9094 57.1831 27.6087 56.4838L45.5925 38.5L27.6087 20.5162C26.9094 19.8169 26.9094 18.6831 27.6087 17.9838Z"
                                        fill="white"
                                        fillOpacity="0.8"
                                    />
                                </svg>
                            </div>
                        )}
                    </div>
                </>
            )}
            {index === 5 && (
                <div className="last__march">
                    <img src={lastMarch} alt="" />
                    <img id="mobile__march" src={lastMarch_mobile} alt="" />
                    <svg
                        width="42"
                        height="42"
                        viewBox="0 0 42 42"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => setShow(false)}
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.1864 13.1866C13.5639 12.8091 14.1759 12.8091 14.5534 13.1866L28.8107 27.4438C29.1882 27.8213 29.1882 28.4333 28.8107 28.8108C28.4332 29.1883 27.8212 29.1883 27.4437 28.8108L13.1864 14.5535C12.809 14.176 12.809 13.564 13.1864 13.1866Z"
                            fill="#BBBBBB"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M28.8116 13.1866C29.1891 13.564 29.1891 14.176 28.8116 14.5535L14.5543 28.8108C14.1769 29.1883 13.5648 29.1883 13.1874 28.8108C12.8099 28.4333 12.8099 27.8213 13.1874 27.4438L27.4446 13.1866C27.8221 12.8091 28.4341 12.8091 28.8116 13.1866Z"
                            fill="#BBBBBB"
                        />
                        <circle
                            cx="21"
                            cy="21"
                            r="20"
                            stroke="#BBBBBB"
                            strokeWidth="2"
                        />
                    </svg>
                </div>
            )}
            {index < data.length && (
                <div
                    onClick={() => setIndex(index + 1)}
                    className="row__icon__next"
                >
                    <svg
                        width="77"
                        height="77"
                        viewBox="0 0 77 77"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M27.6087 17.9838C28.308 17.2845 29.4418 17.2845 30.1411 17.9838L49.3911 37.2338C50.0905 37.9331 50.0905 39.0669 49.3911 39.7662L30.1411 59.0162C29.4418 59.7155 28.308 59.7155 27.6087 59.0162C26.9094 58.3169 26.9094 57.1831 27.6087 56.4838L45.5925 38.5L27.6087 20.5162C26.9094 19.8169 26.9094 18.6831 27.6087 17.9838Z"
                            fill="white"
                            fillOpacity="0.8"
                        />
                    </svg>
                </div>
            )}
            {index > 0 && (
                <div
                    onClick={() => setIndex(index - 1)}
                    className="row__icon__prev"
                >
                    <svg
                        width="77"
                        height="77"
                        viewBox="0 0 77 77"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M49.3912 59.0162C48.6919 59.7155 47.558 59.7155 46.8587 59.0162L27.6087 39.7662C26.9094 39.0669 26.9094 37.9331 27.6087 37.2338L46.8587 17.9838C47.5581 17.2845 48.6919 17.2845 49.3912 17.9838C50.0905 18.6831 50.0905 19.8169 49.3912 20.5162L31.4074 38.5L49.3912 56.4838C50.0905 57.1831 50.0905 58.3169 49.3912 59.0162Z"
                            fill="white"
                            fillOpacity="0.8"
                        />
                    </svg>
                </div>
            )}
        </div>
    ) : null;
}

export default March8;
