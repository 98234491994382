import { motion } from "framer-motion";
function NetworkModal({ status }) {
    return (
        <motion.div
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 300, opacity: 0 }}
            className="network__notify"
        >
            <div className={`${status === 1 ? "danger" : "success"} alert`}>
                <div className="content">
                    <div className="icon">
                        {status === 1 ? (
                            <svg
                                height="30"
                                viewBox="0 0 512 512"
                                width="30"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill="#fff"
                                    d="M449.07,399.08,278.64,82.58c-12.08-22.44-44.26-22.44-56.35,0L51.87,399.08A32,32,0,0,0,80,446.25H420.89A32,32,0,0,0,449.07,399.08Zm-198.6-1.83a20,20,0,1,1,20-20A20,20,0,0,1,250.47,397.25ZM272.19,196.1l-5.74,122a16,16,0,0,1-32,0l-5.74-121.95v0a21.73,21.73,0,0,1,21.5-22.69h.21a21.74,21.74,0,0,1,21.73,22.7Z"
                                ></path>
                            </svg>
                        ) : (
                            <svg
                                width="30"
                                height="30"
                                version="1.1"
                                viewBox="0 0 128 128"
                            >
                                <g>
                                    <circle
                                        fill="#fff"
                                        cx="64"
                                        cy="64"
                                        r="64"
                                    ></circle>
                                </g>
                                <g>
                                    <path
                                        fill="#3EBD61"
                                        d="M54.3,97.2L24.8,67.7c-0.4-0.4-0.4-1,0-1.4l8.5-8.5c0.4-0.4,1-0.4,1.4,0L55,78.1l38.2-38.2   c0.4-0.4,1-0.4,1.4,0l8.5,8.5c0.4,0.4,0.4,1,0,1.4L55.7,97.2C55.3,97.6,54.7,97.6,54.3,97.2z"
                                    ></path>
                                </g>
                            </svg>
                        )}
                    </div>
                    <p>
                        {status === 1
                            ? "İnternet bağlantınızda problem var."
                            : "İnternet bağlantınız bərpa oldu."}
                    </p>
                </div>
            </div>
        </motion.div>
    );
}

export default NetworkModal;
