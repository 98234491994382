import { motion } from "framer-motion";
import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { MIX_API_URL } from "../env";
function SupportModal({ dispatch, supportModal }) {
    const [support, setSupport] = useState({
        category: "empty",
        title: "",
        body: "",
    });
    const [error, setError] = useState({
        category: null,
        title: null,
        body: null,
    });
    const handleInput = (e) => {
        setSupport({ ...support, [e.target.name]: e.target.value });
    };
    useEffect(() => {
        let temp = {
            category: null,
            title: null,
            body: null,
        };
        if (support.category === "empty") {
            temp.category = "Kateqoriya seçin.";
        } else {
            temp.category = null;
        }
        if (support.title.trim().length < 3) {
            temp.title = "Başlıq minimum 3 simvol olmalıdır.";
        } else if (support.title.trim().length > 50) {
            temp.title = "Başlıq maksimum 50 simvol olmalıdır.";
        } else {
            temp.title = null;
        }
        if (support.body.trim().length < 10) {
            temp.body = "Mətn minimum 10 simvol olmalıdır.";
        } else if (support.body.trim().length > 8000) {
            temp.body = "Mətn maksimum 8000 simvol olmalıdır.";
        } else {
            temp.body = null;
        }
        setError({ ...temp });
    }, [support]);
    const sendMessage = (e) => {
        e.preventDefault();
        fetch(`${MIX_API_URL}support`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + window.localStorage.getItem("token"),
            },
            body: JSON.stringify(support),
        })
            .then((a) => a.json())
            .then((a) => {
                if (a.status === 200) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Müraciətiniz qeydə alındı !",
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(() => {
                        setError({
                            category: null,
                            title: null,
                            body: null,
                        });
                        setSupport({
                            category: null,
                            title: null,
                            body: null,
                        });
                        dispatch({
                            type: "SUPPORT_MODAL",
                            payload: false,
                        });
                    });
                }
            });
    };
    const closeSupportModal = () => {
        let c = support.category !== "empty" || support.title || support.body;
        if (c) {
            Swal.fire({
                title: "Əminsinizmi ?",
                text: "Daxil edilən məlumatlar silinəcək !",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Bəli",
                cancelButtonText: "Xeyr",
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch({
                        type: "SUPPORT_MODAL",
                        payload: false,
                    });
                }
            });
        } else {
            dispatch({
                type: "SUPPORT_MODAL",
                payload: false,
            });
        }
    };
    return (
        <div onClick={closeSupportModal} className="support-modal-container">
            <motion.div
                onClick={(e) => e.stopPropagation()}
                initial={{ opacity: 0, scale: 0.7 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.7 }}
            >
                <form
                    onSubmit={sendMessage}
                    onClick={(e) => e.stopPropagation()}
                    className="support-modal"
                >
                    <div className="form-row">
                        <label className="cat_label">
                            Kateqoriya
                            <select
                                onChange={handleInput}
                                defaultValue={support.category}
                                name="category"
                            >
                                <option value="empty">Kateqoriya Seçin</option>
                                <option value="Təklif">Təklif</option>
                                <option value="Tərif">Tərif</option>
                                <option value="Şikayət">Şikayət</option>
                                <option value="Digər">Digər</option>
                            </select>
                            {error.category && (
                                <span className="text-danger">
                                    {error.category}
                                </span>
                            )}
                        </label>
                        <label className="title_label">
                            Başlıq
                            <input
                                onChange={handleInput}
                                value={support.title}
                                type="text"
                                name="title"
                                placeholder="Başlıq"
                            />
                            {error.title && (
                                <span className="text-danger">
                                    {error.title}
                                </span>
                            )}
                        </label>
                    </div>
                    <div className="form-row">
                        <label>
                            Mətn
                            <textarea
                                onChange={handleInput}
                                placeholder="Mətn"
                                name="body"
                                value={support.body}
                            ></textarea>
                            {error.body && (
                                <span className="text-danger">
                                    {error.body}
                                </span>
                            )}
                        </label>
                    </div>
                    <button
                        className="btn"
                        disabled={Object.values(error).some((a) => a)}
                        type="submit"
                    >
                        Göndər
                    </button>
                </form>
            </motion.div>
        </div>
    );
}
const t = (a) => {
    return {
        supportModal: a.supportModal,
    };
};
export default connect(t)(SupportModal);
