import "./assets/css/style.css";
import "./assets/css/responsive.css";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState, Suspense, lazy } from "react";
import ProtectedRoute from "./components/ProtectedRoute";
import NetworkModal from "./components/NetworkModal";
import Header from "./components/Header";
const UserHome = lazy(() => import("./pages/user/Home"));
const UserLogin = lazy(() => import("./pages/user/Login"));
const AdminHome = lazy(() => import("./pages/admin/Home"));
const AdminLogin = lazy(() => import("./pages/admin/Login"));
const InstructorHome = lazy(() => import("./pages/instructor/Home"));
const InstructorLogin = lazy(() => import("./pages/instructor/Login"));
const Marks = lazy(() => import("./pages/user/Marks"));
const EditProfile = lazy(() => import("./pages/user/EditProfile"));
const Tasks = lazy(() => import("./pages/user/Tasks"));
const Groups = lazy(() => import("./pages/admin/Groups"));
const Instructors = lazy(() => import("./pages/admin/Instructors"));
const Students = lazy(() => import("./pages/admin/Students"));
const ForgotPassword = lazy(() => import("./pages/admin/ForgotPassword"));
const GroupDetails = lazy(() => import("./pages/admin/GroupDetails"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Mentor = lazy(() => import("./pages/user/Mentor"));
const Messages = lazy(() => import("./pages/admin/Messages"));
const Forum = lazy(() => import("./pages/admin/Forum"));
const Club = lazy(() => import("./pages/Club"));
const UserForum = lazy(() => import("./pages/user/Forum"));
const CreateForum = lazy(() => import("./pages/admin/CreateForum"));
const EditForum = lazy(() => import("./pages/admin/EditForum"));
const ForumDetails = lazy(() => import("./pages/user/ForumDetails"));
const ForumResults = lazy(() => import("./pages/admin/ForumResults"));
const StudentDetails = lazy(() => import("./pages/admin/StudentDetails"));
const Log = lazy(() => import("./pages/admin/Log"));
const Graduates = lazy(() => import("./pages/admin/Graduates"));
const Library = lazy(() => import("./pages/Library"));
const ProjectPending = lazy(() => import("./pages/admin/ProjectPending"));
const Voting = lazy(() => import("./pages/Voting"));
const Votings = lazy(() => import("./pages/admin/Votings"));
const NewGuest = lazy(() => import("./pages/admin/NewGuest"));
const VotingResult = lazy(() => import("./pages/admin/VotingResult"));
const Guests = lazy(() => import("./pages/admin/Guests"));
const DeletedStudents = lazy(() => import("./pages/admin/DeletedStudents"));
const Staff = lazy(() => import("./pages/admin/Staff"));
const BarmenLogin = lazy(() => import("./pages/barmen/Login"));
const BarmenHome = lazy(() => import("./pages/barmen/Home"));
const BarmenMenu = lazy(() => import("./pages/barmen/Menu"));
const BarmenCategories = lazy(() => import("./pages/barmen/Categories"));
const Cafe = lazy(() => import("./pages/Cafe"));
function App() {
  const [network, setNetwork] = useState(null);
  useEffect(() => {
    window.addEventListener("online", () => {
      setNetwork(2);
      setTimeout(() => {
        setNetwork(null);
      }, 3000);
    });
    window.addEventListener("offline", () => {
      setNetwork(1);
    });
  }, []);
  const location = useLocation();
  const navigateTo = window.localStorage.getItem("type");
  const routes = [
    {
      path: "/",
      element: <Navigate to={`/${navigateTo ? navigateTo : "instructor"}`} />,
    },
    {
      path: "/admin/groups/:page",
      element: (
        <ProtectedRoute where="admin">
          <Groups where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/group/:id",
      element: (
        <ProtectedRoute where="admin">
          <GroupDetails where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/club",
      element: (
        <ProtectedRoute where="admin">
          <Club />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/votingresults",
      element: (
        <ProtectedRoute where="admin">
          <VotingResult where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/instructors",
      element: (
        <ProtectedRoute where="admin">
          <Instructors where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/deletedstudents/:page",
      element: (
        <ProtectedRoute where="admin">
          <DeletedStudents where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/guests",
      element: (
        <ProtectedRoute where="admin">
          <Guests where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/staff",
      element: (
        <ProtectedRoute where="admin">
          <Staff where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/students/:page",
      element: (
        <ProtectedRoute where="admin">
          <Students where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/graduates/:page",
      element: (
        <ProtectedRoute where="admin">
          <Graduates where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/projectpending",
      element: (
        <ProtectedRoute where="admin">
          <ProjectPending where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/student/:id",
      element: (
        <ProtectedRoute where="admin">
          <StudentDetails where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/editprofile",
      element: (
        <ProtectedRoute where="admin">
          <EditProfile where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/messages",
      element: (
        <ProtectedRoute where="admin">
          <Messages where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/forum",
      element: (
        <ProtectedRoute where="admin">
          <Forum where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/votings",
      element: (
        <ProtectedRoute where="admin">
          <Votings where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/log",
      element: (
        <ProtectedRoute where="admin">
          <Log where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/createforum",
      element: (
        <ProtectedRoute where="admin">
          <CreateForum where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/createforum",
      element: (
        <ProtectedRoute where="admin">
          <CreateForum where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/newguest",
      element: (
        <ProtectedRoute where="admin">
          <NewGuest where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/editforum/:id",
      element: (
        <ProtectedRoute where="admin">
          <EditForum where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/forumresults/:id",
      element: (
        <ProtectedRoute where="admin">
          <ForumResults where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/forgotpassword",
      element: (
        <ProtectedRoute where="admin">
          <ForgotPassword where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/login",
      element: <AdminLogin />,
    },
    {
      path: "/admin",
      element: (
        <ProtectedRoute where="admin">
          <AdminHome where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/instructor",
      element: (
        <ProtectedRoute where="instructor">
          <InstructorHome where="instructor" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/instructor/club",
      element: (
        <ProtectedRoute where="instructor">
          <Club />
        </ProtectedRoute>
      ),
    },
    {
      path: "/instructor/editprofile",
      element: (
        <ProtectedRoute where="instructor">
          <EditProfile where="instructor" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/user/library",
      element: (
        <ProtectedRoute where="user">
          <Library where="user" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/instructor/library",
      element: (
        <ProtectedRoute where="instructor">
          <Library where="instructor" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/admin/library",
      element: (
        <ProtectedRoute where="admin">
          <Library where="admin" />
        </ProtectedRoute>
      ),
    },
    {
      path: "/instructor/login",
      element: <InstructorLogin />,
    },
    {
      path: "/404",
      element: <NotFound />,
    },
    {
      path: "/voting/:id",
      element: <Voting />,
    },
    {
      path: "*",
      element: <Navigate to="/404" />,
    },
  ];
  return (
    <>
      {!location.pathname.includes("login") &&
        !location.pathname.includes("404") && <Header />}
      <AnimatePresence>
        {network && <NetworkModal status={network} />}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          {routes.map((a) => (
            <Route {...a} key={a.path} />
          ))}
        </Routes>
      </AnimatePresence>
    </>
  );
}
export default App;
