import { NavLink, useLocation } from "react-router-dom";
import coders_cafe from "../assets/images/site/coders-cafe-logo.svg";
import coders_club from "../assets/images/site/coders-club-logo.svg";
import coders_news from "../assets/images/site/coders-news-logo.svg";
import homeIcon from "../assets/icons/home.svg";
import groupsIcon from "../assets/icons/groups.svg";
import instructorIcon from "../assets/icons/instructor.svg";
import studentIcon from "../assets/icons/student.svg";
import libraryIcon from "../assets/icons/library.svg";
import appealsIcon from "../assets/icons/appeals.svg";
import addNewsIcon from "../assets/icons/addNews.svg";
import passwordRecoveryIcon from "../assets/icons/passwordRecovery.svg";
import graduateIcon from "../assets/icons/graduate.svg";
import createForumIcon from "../assets/icons/createForum.svg";
import forumIcon from "../assets/icons/forum.svg";
import marksIcon from "../assets/icons/marks.svg";
import tasksIcon from "../assets/icons/tasks.svg";
import downIcon from "../assets/icons/down-black2.svg";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import novruzHome from "../assets/icons/novruz_home.svg";
import novruzForum from "../assets/icons/novruz_forum.svg";
import novruzMarks from "../assets/icons/novruz_marks.svg";
import novruzTasks from "../assets/icons/novruz_tasks.svg";
import novruzMentor from "../assets/icons/novruz_mentor.svg";
import novruzLibrary from "../assets/icons/novruz_library.svg";
function HeaderNavigation({ where, translations }) {
    const [headerMenuShow, setHeaderMenuShow] = useState(false);
    const { pathname } = useLocation();
    useEffect(() => {
        setHeaderMenuShow(false);
    }, [pathname]);
    let route = pathname
        .split("/")
        .filter((a) => a)
        .at(-1);
    if (/[0-9]/.test(route)) {
        route = pathname
            .split("/")
            .filter((a) => a)
            .at(-2);
    }
    const routeName = translations[translations.activeLanguage][route];
    const date = new Date();
    const isNovruz =
        date.getMonth() === 2 && date.getDate() >= 18 && date.getDate() <= 24;
    return (
        <div
            className="headerNavigation-container"
            onMouseEnter={() => setHeaderMenuShow(true)}
            onMouseLeave={() => setHeaderMenuShow(false)}
        >
            {routeName && (
                <p>
                    {routeName}
                    <img
                        style={{
                            transform: `rotate(${headerMenuShow ? 180 : 0}deg)`,
                        }}
                        src={downIcon}
                        alt=""
                    />
                </p>
            )}
            <AnimatePresence>
                {headerMenuShow && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        <ul className="headerNavigation">
                            <li className={isNovruz ? "no_invert" : ""}>
                                <NavLink to={`/${where}/`}>
                                    <div className="nav-icon">
                                        <img
                                            src={
                                                isNovruz ? novruzHome : homeIcon
                                            }
                                            alt=""
                                        />
                                    </div>
                                    {
                                        translations[
                                            translations.activeLanguage
                                        ].home
                                    }
                                </NavLink>
                            </li>
                            {where === "admin" && (
                                <>
                                    <li>
                                        <NavLink to="/admin/groups/1">
                                            <div className="nav-icon">
                                                <img src={groupsIcon} alt="" />
                                            </div>
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].groups
                                            }
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/admin/instructors">
                                            <div className="nav-icon">
                                                <img
                                                    src={instructorIcon}
                                                    alt=""
                                                />
                                            </div>
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].instructors
                                            }
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/admin/students/1">
                                            <div className="nav-icon">
                                                <img src={studentIcon} alt="" />
                                            </div>
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].students
                                            }
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/admin/messages">
                                            <div className="nav-icon">
                                                <img src={appealsIcon} alt="" />
                                            </div>
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].appeals
                                            }
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink to="/user/marks">
                                            <div className="nav-icon">
                                                <img src={addNewsIcon} alt="" />
                                            </div>
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].addNews
                                            }
                                        </NavLink>
                                    </li> */}
                                    <li>
                                        <NavLink to="/admin/forgotpassword">
                                            <div className="nav-icon">
                                                <img
                                                    src={passwordRecoveryIcon}
                                                    alt=""
                                                />
                                            </div>
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].passwordRecovery
                                            }
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink to="/admin/graduates/1">
                                            <div className="nav-icon">
                                                <img
                                                    src={graduateIcon}
                                                    alt=""
                                                />
                                            </div>
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].graduates
                                            }
                                        </NavLink>
                                    </li> */}
                                    {/* <li>
                                        <NavLink to="/admin/forum">
                                            <div className="nav-icon">
                                                <img
                                                    src={createForumIcon}
                                                    alt=""
                                                />
                                            </div>
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].createForum
                                            }
                                        </NavLink>
                                    </li> */}
                                </>
                            )}
                            {where === "user" && (
                                <>
                                    <li
                                        className={`${route === "marks"
                                            ? `${isNovruz
                                                ? "no_invert"
                                                : ""
                                            } no-hover`
                                            : isNovruz
                                                ? "no_invert"
                                                : ""
                                            }`}
                                        style={{
                                            opacity:
                                                route === "marks" ? 0.4 : 1,
                                        }}
                                    >
                                        <NavLink to="/user/marks">
                                            <div className="nav-icon">
                                                <img
                                                    src={
                                                        isNovruz
                                                            ? novruzMarks
                                                            : marksIcon
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].marks
                                            }
                                        </NavLink>
                                    </li>
                                    <li
                                        className={`${route === "forum"
                                            ? `${isNovruz
                                                ? "no_invert"
                                                : ""
                                            } no-hover`
                                            : isNovruz
                                                ? "no_invert"
                                                : ""
                                            }`}
                                        style={{
                                            opacity:
                                                route === "forum" ? 0.4 : 1,
                                        }}
                                    >
                                        <NavLink to="/user/forum">
                                            <div className="nav-icon">
                                                <img
                                                    src={
                                                        isNovruz
                                                            ? novruzForum
                                                            : forumIcon
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].forum
                                            }
                                        </NavLink>
                                    </li>
                                    <li
                                        className={`${route === "tasks"
                                            ? `${isNovruz
                                                ? "no_invert"
                                                : ""
                                            } no-hover`
                                            : isNovruz
                                                ? "no_invert"
                                                : ""
                                            }`}
                                        style={{
                                            opacity:
                                                route === "tasks" ? 0.4 : 1,
                                        }}
                                    >
                                        <NavLink to="/user/tasks">
                                            <div className="nav-icon">
                                                <img
                                                    src={
                                                        isNovruz
                                                            ? novruzTasks
                                                            : tasksIcon
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                            {
                                                translations[
                                                    translations.activeLanguage
                                                ].tasks
                                            }
                                        </NavLink>
                                    </li>
                                </>
                            )}
                            {/* <li
                                className={`${route === "club" ? "no-hover" : ""
                                    }`}
                                style={{ opacity: route === "club" ? 0.4 : 1 }}
                            >
                                <NavLink to={`/${where}/club`}>
                                    <div className="nav-icon">
                                        <img src={coders_club} alt="" />
                                    </div>
                                    Coders
                                    <br />
                                    Club
                                </NavLink>
                            </li> */}
                            {/* <li
                                className={`${route === "library"
                                    ? `${isNovruz ? "no_invert" : ""
                                    } no-hover`
                                    : isNovruz
                                        ? "no_invert"
                                        : ""
                                    }`}
                                style={{
                                    opacity: route === "library" ? 0.4 : 1,
                                }}
                            >
                                <NavLink to={`/${where}/library`}>
                                    <div className="nav-icon">
                                        <img
                                            src={
                                                isNovruz
                                                    ? novruzLibrary
                                                    : libraryIcon
                                            }
                                            alt=""
                                        />
                                    </div>
                                    {
                                        translations[
                                            translations.activeLanguage
                                        ].library
                                    }
                                </NavLink>
                            </li> */}
                            {/* <li>
                                <a
                                    href="https://cafe.coders.edu.az/"
                                    target="_blank"
                                >
                                    <div className="nav-icon">
                                        <img src={coders_cafe} alt="" />
                                    </div>
                                    Coders
                                    <br />
                                    Cafe
                                </a>
                            </li> */}
                            <li>
                                <a
                                    href="https://dualvet.az/news.aspx/"
                                    target="_blank"
                                >
                                    <div className="nav-icon">
                                        {/* <img src={coders_news} alt="" /> */}
                                        <p className="newsLogoHeaders">
                                            DUALVET
                                            <br />
                                            <span className="logoTexts">NEWS</span>
                                        </p>
                                    </div>
                                    Dualvet
                                    <br />
                                    News
                                </a>
                            </li>
                        </ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}
const t = (a) => {
    return {
        translations: a.translations,
    };
};
export default connect(t)(HeaderNavigation);
