const APP_URL = 'https://performance.coders.edu.az'
const ASSET_URL = 'https://performance.coders.edu.az'
const MIX_APP_URL = 'https://performance.coders.edu.az/'
const MIX_API_URL = 'https://performance.coders.edu.az/api/'
const PUSHER_APP_ID = '1507181'
const PUSHER_APP_KEY = '359cd8da66d59d8c2c9e'
const PUSHER_APP_SECRET = 'a39f8ad3499e49c8275f'
const PUSHER_APP_CLUSTER = 'ap2'
const MIX_PUSHER_APP_KEY = `${PUSHER_APP_KEY}`
const MIX_PUSHER_APP_CLUSTER = `${PUSHER_APP_CLUSTER}`

export { APP_URL, ASSET_URL, MIX_APP_URL, MIX_API_URL, PUSHER_APP_ID, PUSHER_APP_KEY, PUSHER_APP_SECRET, PUSHER_APP_CLUSTER, MIX_PUSHER_APP_KEY, MIX_PUSHER_APP_CLUSTER }