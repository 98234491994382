import logo from "../assets/images/site/logo.png";
import closeMenu from "../assets/icons/close-menu.svg";
import down from "../assets/icons/down.svg";
import settings from "../assets/icons/settings.svg";
import support from "../assets/icons/support.svg";
import logout_icon from "../assets/icons/logout.svg";
import aze from "../assets/icons/aze.svg";
import eng from "../assets/icons/eng.svg";
import rus from "../assets/icons/rus.svg";
import coders_cafe from "../assets/images/site/coders-cafe-logo.svg";
import coders_club from "../assets/images/site/coders-club-logo.svg";
import coders_news from "../assets/images/site/coders-news-logo.svg";
import homeIcon from "../assets/icons/home.svg";
import groupsIcon from "../assets/icons/groups.svg";
import libraryIcon from "../assets/icons/library.svg";
import instructorIcon from "../assets/icons/instructor.svg";
import studentIcon from "../assets/icons/student.svg";
import appealsIcon from "../assets/icons/appeals.svg";
import addNewsIcon from "../assets/icons/addNews.svg";
import passwordRecoveryIcon from "../assets/icons/passwordRecovery.svg";
import graduateIcon from "../assets/icons/graduate.svg";
import createForumIcon from "../assets/icons/createForum.svg";
import forumIcon from "../assets/icons/forum.svg";
import marksIcon from "../assets/icons/marks.svg";
import tasksIcon from "../assets/icons/tasks.svg";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { MIX_APP_URL } from "../env";
function MobileSidebar({
    user,
    navigateTo,
    mobileSideBar,
    setMobileSideBar,
    logout,
    changeLanguage,
    translations,
    dispatch,
}) {
    const langImages = {
        aze: aze,
        eng: eng,
        rus: rus,
    };
    const node2 = useRef();
    const [langMenu, setLangMenu] = useState(false);
    const toggleLangMenu = (e) => {
        if (!node2.current.contains(e.target)) {
            setLangMenu(false);
        }
    };
    useEffect(() => {
        document.addEventListener("click", toggleLangMenu);
        return () => {
            document.removeEventListener("click", toggleLangMenu);
        };
    }, [langMenu]);
    return (
        user && (
            <aside
                className={`mobile-sidebar ${mobileSideBar ? "active" : ""}`}
            >
                <div className="mobile-sidebar-header">
                    <div className="logo">
                        <img src={logo} alt="" />
                    </div>
                    <div
                        onClick={() => setMobileSideBar(false)}
                        className="close-menu"
                    >
                        <img src={closeMenu} alt="" />
                    </div>
                </div>
                <div className="mobile-sidebar-body">
                    <div className="mobile-sidebar-user">
                        {user && (
                            <img
                                src={
                                    user?.photo.startsWith("blob")
                                        ? user?.photo
                                        : user?.photo !== 'student.png' ? `${MIX_APP_URL}storage/avatars/${user?.photo}` : `/images/student.png`
                                }
                                alt=""
                            />
                        )}
                        <h1>{user && `${user.name} ${user.surname}`}</h1>
                    </div>
                    <ul className="mobile-navigation">
                        <li>
                            <NavLink to={`/${navigateTo}/`}>
                                <div className="nav-icon">
                                    <img src={homeIcon} alt="" />
                                </div>
                                {translations[translations.activeLanguage].home}
                            </NavLink>
                        </li>
                        {navigateTo === "admin" && (
                            <>
                                <li>
                                    <NavLink to="admin/groups/1">
                                        <div className="nav-icon">
                                            <img src={groupsIcon} alt="" />
                                        </div>
                                        {
                                            translations[
                                                translations.activeLanguage
                                            ].groups
                                        }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/admin/instructors">
                                        <div className="nav-icon">
                                            <img src={instructorIcon} alt="" />
                                        </div>
                                        {
                                            translations[
                                                translations.activeLanguage
                                            ].instructors
                                        }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/admin/students/1">
                                        <div className="nav-icon">
                                            <img src={studentIcon} alt="" />
                                        </div>
                                        {
                                            translations[
                                                translations.activeLanguage
                                            ].students
                                        }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/admin/messages">
                                        <div className="nav-icon">
                                            <img src={appealsIcon} alt="" />
                                        </div>
                                        {
                                            translations[
                                                translations.activeLanguage
                                            ].appeals
                                        }
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/user/marks">
                                        <div className="nav-icon">
                                            <img src={addNewsIcon} alt="" />
                                        </div>
                                        {
                                            translations[
                                                translations.activeLanguage
                                            ].addNews
                                        }
                                    </NavLink>
                                </li> */}
                                <li>
                                    <NavLink to="/user/marks">
                                        <div className="nav-icon">
                                            <img
                                                src={passwordRecoveryIcon}
                                                alt=""
                                            />
                                        </div>
                                        {
                                            translations[
                                                translations.activeLanguage
                                            ].passwordRecovery
                                        }
                                    </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/admin/graduates/1">
                                        <div className="nav-icon">
                                            <img src={graduateIcon} alt="" />
                                        </div>
                                        {
                                            translations[
                                                translations.activeLanguage
                                            ].graduates
                                        }
                                    </NavLink>
                                </li> */}
                                {/* <li>
                                    <NavLink to="/admin/forum">
                                        <div className="nav-icon">
                                            <img src={createForumIcon} alt="" />
                                        </div>
                                        {
                                            translations[
                                                translations.activeLanguage
                                            ].createForum
                                        }
                                    </NavLink>
                                </li> */}
                            </>
                        )}
                        {navigateTo === "user" && (
                            <>
                                <li>
                                    <NavLink to="/user/marks">
                                        <div className="nav-icon">
                                            <img src={marksIcon} alt="" />
                                        </div>
                                        {
                                            translations[
                                                translations.activeLanguage
                                            ].marks
                                        }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/user/tasks">
                                        <div className="nav-icon">
                                            <img src={tasksIcon} alt="" />
                                        </div>
                                        {
                                            translations[
                                                translations.activeLanguage
                                            ].tasks
                                        }
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="user/forum">
                                        <div className="nav-icon">
                                            <img src={forumIcon} alt="" />
                                        </div>
                                        {
                                            translations[
                                                translations.activeLanguage
                                            ].forum
                                        }
                                    </NavLink>
                                </li>
                            </>
                        )}
                        {/* <li>
                            <NavLink to={`/${navigateTo}/library`}>
                                <div className="nav-icon">
                                    <img src={libraryIcon} alt="" />
                                </div>
                                {
                                    translations[translations.activeLanguage]
                                        .library
                                }
                            </NavLink>
                        </li> */}
                        {/* <li>
                            <NavLink to={`${navigateTo}/club`}>
                                <div className="nav-icon">
                                    <img src={coders_club} alt="" />
                                </div>
                                Coders Club
                            </NavLink>
                        </li>
                        <li>
                            <a
                                href="https://cafe.coders.edu.az/"
                                target="_blank"
                            >
                                <div className="nav-icon">
                                    <img src={coders_cafe} alt="" />
                                </div>
                                Coders Cafe
                            </a>
                        </li> */}
                        <li>
                            <a
                                href="https://coders.edu.az/news/"
                                target="_blank"
                            >
                                <div className="nav-icon">
                                    <p className="newsLogoMobil">
                                        DUALVET
                                        <br />
                                        <span className="logoTexts">NEWS</span>
                                    </p>
                                    {/* <img src={coders_news} alt="" /> */}
                                </div>
                                Dualvet News
                            </a>
                        </li>
                    </ul>
                    <ul className="mobile-navigation mobile-navigation-bottom">
                        <li>
                            <NavLink to={`${navigateTo}/editprofile`}>
                                <img src={settings} alt="" />
                                {
                                    translations[translations.activeLanguage]
                                        .settings
                                }
                            </NavLink>
                        </li>
                        <li>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch({
                                        type: "SUPPORT_MODAL",
                                        payload: true,
                                    });
                                }}
                            >
                                <img src={support} alt="" />
                                {
                                    translations[translations.activeLanguage]
                                        .support
                                }
                            </a>
                        </li>
                        <li>
                            <a onClick={logout} id="logout" href="#">
                                <img src={logout_icon} alt="" />
                                {
                                    translations[translations.activeLanguage]
                                        .logout
                                }
                            </a>
                        </li>
                    </ul>
                    <div className="change-language" ref={node2}>
                        <div
                            className="active-language"
                            onClick={() => setLangMenu((a) => !a)}
                        >
                            <img
                                src={langImages[translations.activeLanguage]}
                                alt=""
                            />
                            <p>{translations.activeLanguage.toUpperCase()}</p>
                            <img
                                className="icon"
                                style={{
                                    transform: `rotate(${langMenu ? 180 : 0
                                        }deg)`,
                                }}
                                src={down}
                                alt=""
                            />
                        </div>
                        <ul
                            className={`language-dropdown ${langMenu ? "shown" : ""
                                }`}
                        >
                            <li onClick={() => changeLanguage("aze")}>
                                <img src={aze} alt="" />
                                <p>AZE</p>
                            </li>
                            <li onClick={() => changeLanguage("eng")}>
                                <img src={eng} alt="" />
                                <p>ENG</p>
                            </li>
                            <li onClick={() => changeLanguage("rus")}>
                                <img src={rus} alt="" />
                                <p>RUS</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </aside>
        )
    );
}
const t = (a) => {
    return {
        user: a.user,
        translations: a.translations,
    };
};
export default connect(t)(MobileSidebar);
