const initialState = {
    birthdayShown: false,
    birthdaysShown: false,
    headerMenuShown: false,
    user: null,
    markModal: false,
    groupInfo: null,
    supportModal: false,
    currentRouteName: null,
    translations: {
        activeLanguage: window.localStorage.getItem("lang")
            ? window.localStorage.getItem("lang")
            : "aze",
        aze: {
            photoUploadBtn: "Şəkil Yüklə",
            photoRemoveBtn: "Şəkli Sil",
            welcome: "Xoş gəlmisiniz",
            group: "Qrup",
            home: "Əsas",
            marks: "Qiymətlər",
            settings: "Hesab Tənzimləmələri",
            editprofile: "Hesab Tənzimləmələri",
            support: "Dəstək",
            logout: "Çıxış et",
            aboutMe: "Məlumatlarım",
            email: "Email",
            phoneNumber: "Mobil nömrə",
            changePassword: "Şifrəni yenilə",
            newPassword: "Yeni Şifrə",
            passwordConfirmation: "Şifrəni yenidən yazın",
            save: "Yadda Saxla",
            absentCount: "Qayıb sayı",
            total: "Ümumi",
            weekly: "Həftəlik",
            totalAverage: "Ümumi ortalama",
            weeklyAverage: "Həftəlik ortalama",
            fromDate: "Tarixindən",
            toDate: "Tarixinə",
            date: "Tarix",
            mark: "Qiymət",
            instructor: "Müəllim",
            instructors: "Müəllimlər",
            task: "Tapşırıq",
            tasks: "Tapşırıqlar",
            showAbsents: "Qayıbları göstər",
            showMarks: "Qiymətləri göstər",
            showPluses: "Plusları göstər",
            clearFilter: "Filteri təmizlə",
            noFilterResult: "Filterlərə uyğun nəticə tapılmadı...",
            topStudents:
                "Cari, ən yüksək qiymət ortalaması olan ilk 5 tələbəmiz",
            groups: "Qruplar",
            uploadTask: "Tapşırıq yüklə",
            confirm: "Təsdiq edin",
            markPlaceholder: "Qiymət",
            addUser: "Şəxs əlavə et",
            student: "Tələbə",
            students: "Tələbələr",
            appeals: "Müraciətlər",
            messages: "Mesajlar",
            addNews: "Xəbər əlavə Et",
            passwordRecovery: "Şifrəni yenilə",
            graduate: "Məzun",
            graduates: "Məzunlar",
            createForum: "Forum yarat",
            createforum: "Forum yarat",
            forum: "Forum",
            mentor: "Mentor",
            club: "Club",
            library: "Kitabxana",
            downloadBook: "Kitabı endir",
            createOnlineLesson: "Dərs bağlantısı yarat",
            deleteOnlineLesson: "Dərs bağlantısını sil",
        },
        eng: {
            messages: "Messages",
            photoUploadBtn: "Upload Photo",
            photoRemoveBtn: "Remove Photo",
            welcome: "Welcome",
            group: "Group",
            home: "Home",
            marks: "Marks",
            settings: "Settings",
            library: "Library",
            downloadBook: "Download book",
            editprofile: "Settings",
            support: "Support",
            logout: "Logout",
            aboutMe: "About Me",
            email: "Email",
            phoneNumber: "Phone number",
            changePassword: "Change password",
            newPassword: "New Password",
            passwordConfirmation: "Repeat password",
            save: "Save",
            absentCount: "Absent count",
            total: "Total",
            weekly: "Weekly",
            totalAverage: "Total average",
            weeklyAverage: "Weekly average",
            fromDate: "From",
            toDate: "To",
            date: "Date",
            mark: "Mark",
            instructor: "Instructor",
            instructors: "Instructors",
            task: "Task",
            tasks: "Tasks",
            showAbsents: "Show Absents",
            showMarks: "Show Marks",
            showPluses: "Show Pluses",
            clearFilter: "Clear Filter",
            noFilterResult: "No results for filter search...",
            topStudents: "Top 5 students with the highest average",
            groups: "Groups",
            uploadTask: "Upload Task",
            confirm: "Confirm",
            markPlaceholder: "Add Mark",
            addUser: "Add user",
            student: "Student",
            students: "Students",
            appeals: "Appeals",
            addNews: "Add news",
            passwordRecovery: "Password Recovery",
            graduate: "Graduate",
            graduates: "Graduates",
            createForum: "Create a forum",
            createforum: "Create a forum",
            forum: "Forum",
            mentor: "Mentor",
            club: "Club",
            createOnlineLesson: "Create online lesson link",
            deleteOnlineLesson: "Delete online lesson link",
        },
        rus: {
            club: "Клуб",
            messages: "Сообщения",
            photoUploadBtn: "Загрузить фото",
            photoRemoveBtn: "Удалить фото",
            welcome: "Добро пожаловать",
            group: "Группа",
            home: "Главная",
            library: "Библиотека",
            downloadBook: "Cкачать книгу",
            marks: "Оценки",
            settings: "Настройки профиля",
            editprofile: "Настройки профиля",
            support: "Помощь",
            logout: "Выйти",
            aboutMe: "Мои данные",
            email: "Электронная почта",
            phoneNumber: "Номер телефона",
            changePassword: "Обновить пароль",
            newPassword: "Новый пароль",
            passwordConfirmation: "Подтвердить пароль",
            save: "Запомнить",
            absentCount: "Количество пропусков",
            total: "Общий",
            weekly: "Недельный",
            totalAverage: "Общее среднее",
            weeklyAverage: "Среднее за неделю",
            fromDate: "С даты",
            toDate: "До даты",
            date: "Дата",
            mark: "Оценка",
            instructor: "Преподаватель",
            instructors: "Преподаватель",
            task: "Задание",
            tasks: "Задания",
            showAbsents: "Показать пропуски",
            showMarks: "Показать оценки",
            showPluses: "Показать плюсы",
            clearFilter: "Очистить фильтр",
            noFilterResult:
                "Не найдено результатов, соответствующих фильтрам...",
            topStudents:
                "5 лучших студентов с самым высоким средним баллом на данный момент",
            groups: "Группы",
            uploadTask: "Загрузить задание",
            confirm: "Подтвердить",
            markPlaceholder: "Оценка",
            addUser: "Добавить пользователя",
            student: "Студент",
            students: "Студент",
            appeals: "Обращения",
            addNews: "Добавить новость",
            passwordRecovery: "Обновить пароль",
            graduate: "Выпускник",
            graduates: "Выпускники",
            createForum: "Создать форум",
            createforum: "Создать форум",
            forum: "Форум",
            mentor: "Mentor",
            createOnlineLesson: "Создать онлайн ссылку",
            deleteOnlineLesson: "Удалить ссылку",
        },
    },
};
export default function Reducer(state = initialState, action) {
    switch (action.type) {
        case "HEADER_MENU":
            return { ...state, headerMenuShown: action.payload };
        case "SET_USER":
            return { ...state, user: action.payload };
        case "SET_GROUPINFO":
            return {
                ...state,
                groupInfo: action.payload,
                markModal: true,
            };
        case "MARK_MODAL":
            return {
                ...state,
                markModal: action.payload,
            };
        case "SUPPORT_MODAL":
            return {
                ...state,
                supportModal: action.payload,
            };
        case "SET_LANGUAGE":
            return {
                ...state,
                translations: {
                    ...state.translations,
                    activeLanguage: action.payload,
                },
            };
        case "SET_BIRTHDAYSHOWN":
            return {
                ...state,
                birthdayShown: action.payload,
            };
        case "SET_BIRTHDAYSSHOWN":
            return {
                ...state,
                birthdaysShown: action.payload,
            };
        default:
            return state;
    }
}
